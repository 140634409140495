@import url("https://fonts.googleapis.com/css2?family=Homemade+Apple&display=swap");

pre {
    font-size: 12px;
}

@media print {
    /* Hide all elements that should not be printed */
    #root:not(.allow-print) {
        display: none !important;
    }

    /* If you have sections or divs that should be kept together, use this class */
    .keep-together {
        page-break-inside: avoid;
        break-inside: avoid-page;
    }

    html,
    body {
        background: white !important;
        zoom: 0.9;
        -webkit-print-color-adjust: exact;
    }

    * {
        overflow: visible !important;
    }

    @page {
        size: A4;
        margin: 2cm;
    }

    @page cover-page {
        margin: 0;
    }

    .cover-page {
        page: cover-page;
    }

    @page landscape-page {
        size: A4 landscape;
    }

    .landscape-page {
        page: landscape-page;
    }

    /* Add page breaks */
    .page-break {
        page-break-after: always;
        break-after: page;
    }

    .print-footer {
        display: block;
        position: fixed;
        bottom: 0;
    }
}

.print-footer-container {
    display: table-footer-group;
    height: 55px;
}

.schedule-details td {
    padding: 4px;
}

.text-difference {
    display: inline;

    border-radius: 2px;
    background-color: #0002;
}

@media (min-width: 500px) {
    button p:first-child {
        margin: 0;
    }

    ::-webkit-scrollbar-thumb {
        transition: background linear 1s !important;
    }

    ::-webkit-scrollbar {
        transition: background-color linear 1s !important;
        width: 4px; /* Mostly for vertical scrollbars */
        height: 4px; /* Mostly for horizontal scrollbars */
    }

    ::-webkit-scrollbar-corner {
        background: transparent;
    }

    .styled-scrollbars ::-webkit-scrollbar-thumb {
        /* Foreground */
        transition: background-color linear 1s !important;
        background-color: #38939b60;
        border-radius: 12px;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .high-contrast-mode ::-webkit-scrollbar {
        width: 15px; /* Mostly for vertical scrollbars */
        height: 15px;
    }

    .high-contrast-mode.styled-scrollbars ::-webkit-scrollbar-thumb {
        /* Foreground */
        transition: background-color linear 1s !important;
        background-color: #38939bdd;
    }

    ::-webkit-scrollbar-track {
        /* Background */
        background: #3330;
        border-radius: 12px;
        margin-top: 12px;
        margin-bottom: 12px;
    }
}

.flashable {
    transition: background-color 0.5s linear !important;
}

.flash {
    background-color: #1eb99d30 !important;
}

.schedule-details div {
    line-height: 1.2;
}

/*.jodit-resizer {*/
/*    display: none !important;*/
/*}*/

li br + br {
    display: none;
}

main {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
}

div #importedTC {
    width: 945px;
    margin-left: auto;
    margin-right: auto;
}

div#importedTC main {
    height: auto !important;
    overflow: auto;
}

div#importedTC svg:first-of-type {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    background-image: url("https://25215107.fs1.hubspotusercontent-eu1.net/hubfs/25215107/SFG20_April2022/image/disclaimer%20image.jpg");
    background-repeat: no-repeat;
    height: 400px;
}

div#importedTC .svg-container-mobile {
    display: none;
}

.iconSpin {
    animation: spin 5s infinite ease-in-out;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(180deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* JODIT */
.jodit-container {
    border-radius: 8px !important;
}

* {
    user-select: none;
}

[contenteditable],
[contenteditable] *,
input,
textarea,
select {
    user-select: initial;
}

.jodit-toolbar__box {
    background: #fff !important;
    border-top-right-radius: 8px !important;
    border-top-left-radius: 8px !important;
}

.jodit-workplace {
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}

[role="button"] {
    cursor: pointer;
}

.jodit-toolbar-button__text {
    font-weight: bold !important;
    font-family: Lato, sans-serif !important;
    color: #000000;
    padding-bottom: 4px !important;
}

.jodit-popup__content .jodit-toolbar-button__text {
    font-weight: initial !important;
}

.jodit-icon {
    fill: #000000 !important;
}
